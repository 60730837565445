"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtUserdata = void 0;
const debug_1 = require("debug");
const utils_1 = require("./utils");
const log = debug_1.debug('xumm-sdk:xapp:userdata');
class JwtUserdata {
    constructor(MetaObject) {
        log('Constructed');
        this.Meta = MetaObject;
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('userdata', 'GET');
            utils_1.throwIfError(call);
            return call.keys;
        });
    }
    get(key) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const keys = Array.isArray(key) ? key.join(',') : key;
            const call = yield this.Meta.call('userdata/' + keys, 'GET');
            utils_1.throwIfError(call);
            return keys.split(',').length > 1 ? call.data : (((_a = call.data) === null || _a === void 0 ? void 0 : _a[keys]) || {});
        });
    }
    delete(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('userdata/' + key, 'DELETE');
            utils_1.throwIfError(call);
            return call.persisted;
        });
    }
    set(key, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('userdata/' + key, 'POST', data);
            utils_1.throwIfError(call);
            return call.persisted;
        });
    }
}
exports.JwtUserdata = JwtUserdata;
