"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payload = void 0;
const debug_1 = require("debug");
const websocket_1 = require("websocket");
const utils_1 = require("./utils");
const log = debug_1.debug('xumm-sdk:payload');
const logWs = debug_1.debug('xumm-sdk:payload:websocket');
class Payload {
    constructor(MetaObject) {
        log('Constructed');
        this.Meta = MetaObject;
    }
    resolvePayload(payload) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof payload === 'string') {
                return yield this.get(payload, true);
            }
            else if (typeof ((_a = payload) === null || _a === void 0 ? void 0 : _a.uuid) !== 'undefined') {
                return yield this.get(payload.uuid, true);
            }
            else if (typeof ((_c = (_b = payload) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.uuid) !== 'undefined') {
                return payload;
            }
            throw new Error('Could not resolve payload (not found)');
        });
    }
    create(payload, returnErrors = false) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const directTx = typeof payload.TransactionType !== 'undefined'
                && typeof payload.txjson === 'undefined';
            const call = yield this.Meta.call('payload', 'POST', directTx ? { txjson: payload } : payload);
            if (returnErrors) {
                utils_1.throwIfError(call);
            }
            const isCreatedPayload = ((_a = call) === null || _a === void 0 ? void 0 : _a.next) !== undefined;
            if (!isCreatedPayload) {
                return null;
            }
            return call;
        });
    }
    get(payload, returnErrors = false) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const payloadUuid = typeof payload === 'string'
                ? payload
                : payload === null || payload === void 0 ? void 0 : payload.uuid;
            const call = yield this.Meta.call('payload/' + payloadUuid, 'GET');
            if (returnErrors) {
                utils_1.throwIfError(call);
            }
            const isPayload = ((_b = (_a = call) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.uuid) !== undefined;
            if (!isPayload) {
                return null;
            }
            return call;
        });
    }
    subscribe(payload, callback) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const callbackPromise = new utils_1.DeferredPromise();
            const payloadDetails = yield this.resolvePayload(payload);
            if (payloadDetails) {
                const _u = 'undefined'; // For globalThis.mockedWebSocket (leave note for Deno gen!)
                const socket = typeof ((_a = globalThis) === null || _a === void 0 ? void 0 : _a.MockedWebSocket) !== _u && typeof jest !== _u
                    ? new ((_b = globalThis) === null || _b === void 0 ? void 0 : _b.MockedWebSocket)('ws://xumm.local')
                    : new websocket_1.w3cwebsocket(this.Meta.endpoint.replace(/^http/, 'ws') + '/sign/' + payloadDetails.meta.uuid);
                callbackPromise.promise.then(() => {
                    socket.close();
                });
                socket.onopen = () => {
                    logWs(`Payload ${payloadDetails.meta.uuid}: Subscription active (WebSocket opened)`);
                };
                socket.onmessage = (MessageEvent) => __awaiter(this, void 0, void 0, function* () {
                    const m = MessageEvent.data;
                    let json = undefined;
                    try {
                        json = JSON.parse(m.toString());
                    }
                    catch (e) {
                        // Do nothing
                        logWs(`Payload ${payloadDetails.meta.uuid}: Received message, unable to parse as JSON`, e);
                    }
                    if (json && callback && typeof json.devapp_fetched === 'undefined') {
                        try {
                            // log(`Payload ${payload}`, json)
                            const callbackResult = yield callback({
                                uuid: payloadDetails.meta.uuid,
                                data: json,
                                resolve(resolveData) {
                                    return __awaiter(this, void 0, void 0, function* () {
                                        yield callbackPromise.resolve(resolveData || undefined);
                                    });
                                },
                                payload: payloadDetails
                            });
                            if (callbackResult !== undefined) {
                                callbackPromise.resolve(callbackResult);
                            }
                        }
                        catch (e) {
                            // Do nothing
                            logWs(`Payload ${payloadDetails.meta.uuid}: Callback exception`, e);
                        }
                    }
                });
                socket.onclose = (_e) => {
                    logWs(`Payload ${payloadDetails.meta.uuid}: Subscription ended (WebSocket closed)`);
                };
                return {
                    payload: payloadDetails,
                    resolve(resolveData) {
                        callbackPromise.resolve(resolveData || undefined);
                    },
                    resolved: callbackPromise.promise,
                    websocket: socket
                };
            }
            utils_1.throwIfError(payloadDetails);
            throw Error(`Couldn't subscribe: couldn't fetch payload`);
        });
    }
    cancel(payload, returnErrors = false) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const fullPayload = yield this.resolvePayload(payload);
            const call = yield this.Meta.call('payload/' + ((_a = fullPayload === null || fullPayload === void 0 ? void 0 : fullPayload.meta) === null || _a === void 0 ? void 0 : _a.uuid), 'DELETE');
            if (returnErrors) {
                utils_1.throwIfError(call);
            }
            const isValidResponse = ((_c = (_b = call) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.uuid) !== undefined;
            if (!isValidResponse) {
                return null;
            }
            return call;
        });
    }
    createAndSubscribe(payload, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdPayload = yield this.create(payload, true);
            if (createdPayload) {
                const subscription = yield this.subscribe(createdPayload, callback);
                return Object.assign({ created: createdPayload }, subscription);
            }
            throw new Error(`Error creating payload or subscribing to created payload`);
        });
    }
}
exports.Payload = Payload;
