"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.xApp = void 0;
const debug_1 = require("debug");
const JwtUserdata_1 = require("./JwtUserdata");
const utils_1 = require("./utils");
const log = debug_1.debug('xumm-sdk:xapp');
class xApp {
    constructor(MetaObject) {
        log('Constructed');
        this.Meta = MetaObject;
        // Backwards compatibility for old Sdk.xApp.userdata users
        // - as xApps used to be the only JWT issuing env. - PKCE now is as well
        this.userdata = new JwtUserdata_1.JwtUserdata(MetaObject);
    }
    get(ott) {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('xapp/ott/' + ott, 'GET');
            utils_1.throwIfError(call);
            return call;
        });
    }
}
exports.xApp = xApp;
